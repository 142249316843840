import styled from "@emotion/styled"
import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Wobble } from "../styles/animations"
import { HeroSection, HeroEmoji, HeroTitle, HeroContent } from "../styles/Hero"

const WobblingEmoji = styled.span`
  display: inline-block;
  animation: ${Wobble} 5s ease infinite;
`

const IndexPage = ({}: PageProps) => (
  <Layout>
    <SEO title="Home" />

    <HeroSection>
      <HeroEmoji>
        <WobblingEmoji>👋</WobblingEmoji>
      </HeroEmoji>
      <HeroTitle>
        Hi, I'm Ryan! An agile developer, creative designer, problem solver and
        occasionally a teacher.
      </HeroTitle>
      <HeroContent>
        <p>
          I love working with brands to develop amazing user experiences – be it
          a hip new mobile app, a custom website or just helping you find ways
          to be more productive.
        </p>

        <p>
          I enjoy exploring the problem space with you to figure out the best
          technology solution to achieve your goals. And if that solution
          includes a hardware component, no problem. I'm not only a coder, but
          also a maker and tinkerer, able to look at hardware, software and
          design with a unique point of view.
        </p>

        <p>
          There aren't a lot of coders who also understand and live on the
          creative side – I'm one of those rare people.
        </p>
      </HeroContent>
    </HeroSection>
  </Layout>
)

export default IndexPage
