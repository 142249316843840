import { keyframes } from "@emotion/core"

export const Wobble = keyframes`
  0% {
    transform: translateZ(0)
  }

  4% {
    transform: translate3d(-25%,0,0) rotate(-25deg)
  }

  8% {
    transform: translate3d(20%,0,0) rotate(35deg)
  }

  12% {
    transform: translate3d(-15%,0,0) rotate(-20deg)
  }

  16% {
    transform: translate3d(10%,0,0) rotate(15deg)
  }

  20% {
    transform: translate3d(-5%,0,0) rotate(-5deg)
  }

  24%, 100% {
    transform: translate3d(0,0,0) rotate(0deg)
  }

  to {
    transform: translateZ(0)
  }
`
